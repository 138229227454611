<template>
    <div class="hero" id="home">
        <!-- <div class="cloud"></div> -->
        <div class="hero-container">
            <div id="mansion-img-ctn">
                <img src="../../static/img/oni-mansion-banner-frame-gif.gif" id="mansion-img-gif" alt="Oni Mansion Game GIF">
            </div>
            
            <header id="header">
                <div>
                    <div id="main-copy">
                        <img src="../../static/img/logo_game.png" id="logo" alt="Oni Squad Logo">
                        <p class="house-game">Players built their own NFTs playing Oni Mansion! </p>
                        
                        <div class="modal-content connect-button m-auto" >
                            <div class="contents inline-menu mt-15">
                                <div class="p-discord">
                                    <div class="p-btn mb-10">
                                        <a class="mint-link" target="_blank" href="https://opensea.io/collection/oni-mansion-nft">See Mansion NFTs On OpenSea</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="house-game">Game Event Over</p>
                        <p class="house-game sub">
                            <small>
                                Oni Squad, Curious Addy's Trading Club, Baby Spirit NFTs, Lazy Lions and Jungle Freaks were all able to play the game.
                            </small>
                        </p>
                        
                        <!-- <div class="clock" v-if="!enableMint"></div> -->
                    </div>
                </div>
            </header>
        </div>
    </div>
</template>
<script src="./Banner.js"></script>
